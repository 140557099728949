import React, { useCallback, useState } from 'react';
import classnames from 'classnames';

import './CameraTooltip.scss';
import { ReactComponent as InfoIcon } from '../../assets/icons/informacion_outline.svg';
import PhotoId from '../../enums';

import panoramicaAccidente from '/../../assets/images/camera-tooltip/panoramica_accidente.png';
import panoramicaVehiculo from '/../../assets/images/camera-tooltip/panoramica_vehiculo.png';
import vehiculoTrasera from '/../../assets/images/camera-tooltip/veh_back.jpg';
import vehiculoDano from '/../../assets/images/camera-tooltip/veh_daño.png';
import vehiculoIzquierda from '/../../assets/images/camera-tooltip/veh_left.jpg';
import vehiculoIzquierdaFrontal from '/../../assets/images/camera-tooltip/veh_left_front.jpg';
import vehiculoIzquierdaTrasera from '/../../assets/images/camera-tooltip/veh_left_back.jpg';
import vehiculoDerecha from '/../../assets/images/camera-tooltip/veh_right.jpg';
import vehiculoDerechaFrontal from '/../../assets/images/camera-tooltip/veh_right_front.jpg';
import vehiculoDerechaTrasera from '/../../assets/images/camera-tooltip/veh_right_back.jpg';
import vehiculoFrontal from '/../../assets/images/camera-tooltip/veh_front.jpg';
import vehiculoMotor from '/../../assets/images/camera-tooltip/car_engine.jpg';

import portrait from '/../../assets/images/camera-tooltip/portrait.png';
import trunk from '/../../assets/images/camera-tooltip/trunk.png';
import dekra from '/../../assets/images/camera-tooltip/dekra.png';

import vehiculoInterior from '/../../assets/images/camera-tooltip/veh_interior.png';
import vehiculoInteriorTrasero from '/../../assets/images/camera-tooltip/veh_interior_trasero.png';
import vehiculoOdometro from '/../../assets/images/camera-tooltip/veh_odometro.png';
import vehiculoMarbete from '/../../assets/images/camera-tooltip/veh_marbete.png';
import vehiculoVin from '/../../assets/images/camera-tooltip/veh_vin.png';
import vehiculoPlaca from '/../../assets/images/camera-tooltip/CRI-PLATE.png';
import vehiculoPlacaPA from '/../../assets/images/camera-tooltip/PAN-PLATE.png';
import vehiculoTablillaPRI from '/../../assets/images/camera-tooltip/PRI-PLATE.png';
import vehiculoPlacaCol from '/../../assets/images/camera-tooltip/placa_colombia.png';
import vehiculoRiteve from '/../../assets/images/camera-tooltip/veh_riteve.png';
import vehiculoMarchamo from '/../../assets/images/camera-tooltip/veh_marchamo.png';
import cedula from '/../../assets/images/camera-tooltip/cedula.png';
import cedulacr from '/../../assets/images/camera-tooltip/cedulacr.png';
import circulacioncr from '/../../assets/images/camera-tooltip/circulacioncr.png';
import licenciaPa from '/../../assets/images/camera-tooltip/licenciaPanama.png';
import licenciaPr from '/../../assets/images/camera-tooltip/licenciaPuertoRico.png';
import licenciaCr from '/../../assets/images/camera-tooltip/licenciaCostaRica.png';
import licenciaCol from '/../../assets/images/camera-tooltip/licencia_conduccion_colombia.png';
import licenciaVehiculoPr from '/../../assets/images/camera-tooltip/veh_licencia_pr.png';
import licenciaVehiculoCol from '/../../assets/images/camera-tooltip/licencia_transito_colombia.png';
import registroUnico from '/../../assets/images/camera-tooltip/registro_unico.png';
import accidentDeclaration from '/../../assets/images/camera-tooltip/declaracion_accidente.png';
import otrosImgUrl from '/../../assets/images/camera-tooltip/agregar_otros.png';
import boletaPa from '/../../assets/images/camera-tooltip/boleta.jpg';
import fud from '/../../assets/images/camera-tooltip/FUD.png';
import crash from '/../../assets/images/camera-tooltip/crash.jpg';
import ruvPa from '/../../assets/images/camera-tooltip/ruvpa.png';
import licenseBackPa from '/../../assets/images/camera-tooltip/licencia_barcode_panama.png';
import backcedulacr from '/../../assets/images/camera-tooltip/backcedulacr.png';

/** Will return undefined if unknown PhotoId. Prob best not to show tooltip in that case */
export function getTooltipContents(id: PhotoId): TooltipContent | null {
	if (
		[
			PhotoId.PANORAMIC,
			PhotoId.INSURED_VEHICLE_PANORAMIC,
			PhotoId.INSURED_VEHICLE_PANORAMIC_CRC,
			PhotoId.INSURED_VEHICLE_PANORAMIC_PAN,
		].includes(id)
	) {
		return {
			title: 'Panorámica del Accidente',
			imgUrl: panoramicaAccidente,
			paragraph: (
				<p>
					Tomar fotografía panorámica del accidente, donde se pueda observar la{' '}
					<b>escena completa del accidente</b>
				</p>
			),
		};
	} else if (
		[
			PhotoId.INSURED_VEHICLE,
			PhotoId.INJURED_VEHICLE,
			PhotoId.COUNTERPART_VEHICLE,
		].includes(id)
	) {
		const owner = {
			[PhotoId.INSURED_VEHICLE as string]: 'asegurado',
			[PhotoId.INJURED_VEHICLE as string]: 'perjudicado',
			[PhotoId.COUNTERPART_VEHICLE as string]: 'de la contraparte',
		}[id as any];

		return {
			title: 'Panorámica del Vehículo',
			imgUrl: panoramicaVehiculo,
			paragraph: (
				<p>
					Tomar fotografía panorámica del accidente, donde{' '}
					<b>se pueda observar mayormente el vehículo {owner}</b>
				</p>
			),
		};
	} else if (
		[
			PhotoId.INSURED_VEHICLE_BACK,
			PhotoId.INJURED_VEHICLE_BACK,
			PhotoId.COUNTERPART_VEHICLE_BACK,
		].includes(id)
	) {
		return {
			title: 'Parte Trasera Vehículo',
			imgUrl: vehiculoTrasera,
			paragraph: (
				<p>
					Tomar fotografía de la parte trasera del vehículo asegurándose que la{' '}
					<b>placa sea visible y legible</b>
				</p>
			),
		};
	} else if (
		[
			PhotoId.INSURED_VEHICLE_DAMAGE_1,
			PhotoId.INSURED_VEHICLE_DAMAGE_2,
			PhotoId.INSURED_VEHICLE_DAMAGE_3,
			PhotoId.INSURED_VEHICLE_DAMAGE_4,
			PhotoId.INJURED_VEHICLE_DAMAGE_1,
			PhotoId.INJURED_VEHICLE_DAMAGE_2,
			PhotoId.INJURED_VEHICLE_DAMAGE_3,
			PhotoId.INJURED_VEHICLE_DAMAGE_4,
			PhotoId.COUNTERPART_VEHICLE_DAMAGE_1,
			PhotoId.COUNTERPART_VEHICLE_DAMAGE_2,
			PhotoId.COUNTERPART_VEHICLE_DAMAGE_3,
		].includes(id)
	) {
		const title = id.substr(id.length - 1);

		return {
			title: `Daño ${title}`,
			imgUrl: vehiculoDano,
			paragraph: (
				<p>
					Tomar fotografía donde se muestre el{' '}
					<b>daño que presenta el vehículo</b> producto de la colisión
				</p>
			),
		};
	} else if (
		[
			PhotoId.INSURED_LICENSE_PR,
			PhotoId.INJURED_LICENSE_PR,
			PhotoId.COUNTERPART_LICENSE_PA,
			PhotoId.INSURED_LICENSE_CR,
			PhotoId.INJURED_LICENSE_CR,
		].includes(id)
	) {
		const imgUrl = {
			[PhotoId.COUNTERPART_LICENSE_PA as string]: licenciaPa,
			[PhotoId.INSURED_LICENSE_PR as string]: licenciaPr,
			[PhotoId.INJURED_LICENSE_PR as string]: licenciaPr,
			[PhotoId.INSURED_LICENSE_CR as string]: licenciaCr,
			[PhotoId.INJURED_LICENSE_CR as string]: licenciaCr,
		}[id as any];

		return {
			title: 'Licencia de Conducir',
			imgUrl: imgUrl,
			paragraph: (
				<p>
					Tomar fotografía de la licencia{' '}
					<b>sobre una superficie plana asegurándose que sea legible</b>
				</p>
			),
		};
	} else if (
		[
			PhotoId.INSURED_VEHICLE_LICENSE_PR,
			PhotoId.INJURED_VEHICLE_LICENSE_PR,
		].includes(id)
	) {
		return {
			title: 'Licencia de Vehículo',
			imgUrl: licenciaVehiculoPr,
			paragraph: (
				<p>
					Tomar fotografía sobre de la licencia del vehículo{' '}
					<b>
						sobre una superficie plana asegurándose que esté completa y que sea
						legible
					</b>
				</p>
			),
		};
	} else if ([PhotoId.VEHICLE_LICENSE_COL].includes(id)) {
		return {
			title: 'Permiso de Circulación',
			imgUrl: licenciaVehiculoCol,
			paragraph: (
				<p>
					Tomar fotografía de la licencia del vehículo{' '}
					<b>
						sobre una superficie plana asegurándose que esté completa y que sea
						legible
					</b>
				</p>
			),
		};
	} else if (id === PhotoId.COUNTERPART_REGISTRO_UNICO) {
		return {
			title: 'Registro Único',
			imgUrl: registroUnico,
			paragraph: (
				<p>
					Tomar fotografía sobre una superficie plana al Registro Único
					Vehicular <b>asegurándose que esté completo y que sea legible</b>
				</p>
			),
		};
	} else if (id === PhotoId.COUNTERPART_CEDULA) {
		return {
			title: 'Cédula',
			imgUrl: cedula,
			paragraph: (
				<p>
					Tomar fotografía de la cédula{' '}
					<b>sobre una superficie plana asegurándose que sea legible</b>
				</p>
			),
		};
	} else if (id === PhotoId.COUNTERPART_CEDULA_CR) {
		return {
			title: 'Cédula de identidad frontal',
			imgUrl: cedulacr,
			paragraph: (
				<p>
					Tomar fotografía de la cédula{' '}
					<b>sobre una superficie plana asegurándose que sea legible</b>
				</p>
			),
		};
	} else if (
		[
			PhotoId.INSURED_LICENSE_OR_CEDULA_PA,
			PhotoId.INSURED_LICENSE_OR_CEDULA_CRC,
		].includes(id)
	) {
		return {
			title: 'Cédula o Licencia',
			paragraph: (
				<p>
					Tomar fotografía de la cédula de identidad o licencia de conducir{' '}
					<b>sobre una superficie plana asegurándose que sea legible</b>
				</p>
			),
		};
	} else if ([PhotoId.USER_LICENSE_COL].includes(id)) {
		return {
			title: 'Licencia',
			imgUrl: licenciaCol,
			paragraph: (
				<p>
					Tomar fotografía de la licencia de conducir{' '}
					<b>sobre una superficie plana asegurándose que sea legible</b>
				</p>
			),
		};
	} else if (
		[PhotoId.INSURED_VEHICLE_FRONT, PhotoId.INJURED_VEHICLE_FRONT].includes(id)
	) {
		return {
			title: 'Parte Frontal Vehículo',
			imgUrl: vehiculoFrontal,
			paragraph: (
				<p>
					Tomar fotografía de la <b>parte frontal del vehículo</b> como se
					muestra en la imagen
				</p>
			),
		};
	} else if (
		[
			PhotoId.INSURED_VEHICLE_DRIVER_SIDE,
			PhotoId.INJURED_VEHICLE_DRIVER_SIDE,
		].includes(id)
	) {
		return {
			title: 'Parte Lateral Izquierdo',
			imgUrl: vehiculoIzquierda,
			paragraph: (
				<p>
					Tomar fotografía del costado izquierdo del vehículo (
					<b>lado del conductor</b>)
				</p>
			),
		};
	} else if ([PhotoId.INJURED_VEHICLE_DRIVER_SIDE_FRONT].includes(id)) {
		return {
			title: 'Parte Lateral Izquierdo Frontal',
			imgUrl: vehiculoIzquierdaFrontal,
			paragraph: (
				<p>
					Tomar fotografía del costado izquierdo frontal del vehículo (
					<b>lado del conductor</b>)
				</p>
			),
		};
	} else if ([PhotoId.VEHICLE_INTERIOR_MOTOR_PANO].includes(id)) {
		return {
			title: 'Panorámica del motor del vehículo',
			imgUrl: vehiculoMotor,
			paragraph: (
				<p>
					Tomar fotografía (<b>panorámica del motor del vehículo</b>)
				</p>
			),
		};
	} else if ([PhotoId.INJURED_VEHICLE_DRIVER_SIDE_BACK].includes(id)) {
		return {
			title: 'Parte Lateral Izquierdo Trasero',
			imgUrl: vehiculoIzquierdaTrasera,
			paragraph: (
				<p>
					Tomar fotografía del costado izquierdo trasero del vehículo (
					<b>lado del conductor</b>)
				</p>
			),
		};
	} else if (
		[
			PhotoId.INSURED_VEHICLE_PASSENGER_SIDE,
			PhotoId.INJURED_VEHICLE_PASSENGER_SIDE,
		].includes(id)
	) {
		return {
			title: 'Parte Lateral Derecho',
			imgUrl: vehiculoDerecha,
			paragraph: (
				<p>
					Tomar fotografía de costado derecho del vehículo (
					<b>lado del pasajero</b>)
				</p>
			),
		};
	} else if ([PhotoId.INJURED_VEHICLE_PASSENGER_SIDE_FRONT].includes(id)) {
		return {
			title: 'Parte Lateral Derecho Frontal',
			imgUrl: vehiculoDerechaFrontal,
			paragraph: (
				<p>
					Tomar fotografía de costado derecho frontal del vehículo (
					<b>lado del pasajero</b>)
				</p>
			),
		};
	} else if ([PhotoId.INJURED_VEHICLE_PASSENGER_SIDE_BACK].includes(id)) {
		return {
			title: 'Parte Lateral Derecho Trasero',
			imgUrl: vehiculoDerechaTrasera,
			paragraph: (
				<p>
					Tomar fotografía de costado derecho trasero del vehículo (
					<b>lado del pasajero</b>)
				</p>
			),
		};
	} else if (
		[
			PhotoId.INSURED_VEHICLE_INTERIOR_1,
			PhotoId.INJURED_VEHICLE_INTERIOR_1,
		].includes(id)
	) {
		return {
			title: 'Vehículo Interior Frontal',
			imgUrl: vehiculoInterior,
			paragraph: (
				<p>
					Tomar fotografía al <b>interior frontal del vehículo</b>
				</p>
			),
		};
	} else if (
		[
			PhotoId.INSURED_VEHICLE_INTERIOR_2,
			PhotoId.INJURED_VEHICLE_INTERIOR_2,
		].includes(id)
	) {
		return {
			title: 'Vehículo Interior Trasero',
			imgUrl: vehiculoInteriorTrasero,
			paragraph: (
				<p>
					Tomar fotografía al <b>interior trasero del vehículo</b>
				</p>
			),
		};
	} else if (
		[
			PhotoId.INSURED_VEHICLE_ODOMETER,
			PhotoId.INJURED_VEHICLE_ODOMETER,
		].includes(id)
	) {
		return {
			title: 'Odómetro Vehículo',
			imgUrl: vehiculoOdometro,
			paragraph: (
				<p>
					Tomar fotografía al <b>odómetro del vehículo</b> con el vehículo encendido tal y 
					como se muestra en la imagen
				</p>
			),
		};
	} else if (
		[
			PhotoId.INSURED_VEHICLE_PLATE_PR,
			PhotoId.INSURED_VEHICLE_PLATE_PR,
		].includes(id)
	) {
		return {
			title: 'Tablilla Vehículo',
			imgUrl: vehiculoTablillaPRI,
			paragraph: (
				<p>
					Tomar fotografía a la tablilla del vehículo{' '}
					<b>asegurándose de que sea legible</b>
				</p>
			),
		};
	} else if (
		[
			PhotoId.INJURED_VEHICLE_PLATE_PR,
			PhotoId.INJURED_VEHICLE_PLATE_PR,
		].includes(id)
	) {
		return {
			title: 'Tablilla Vehículo',
			imgUrl: vehiculoTablillaPRI,
			paragraph: (
				<p>
					Tomar fotografía a la tablilla del vehículo{' '}
					<b>asegurándose de que sea legible</b>
				</p>
			),
		};
	} else if (
		[
			PhotoId.INSURED_VEHICLE_PLATE_CR,
			PhotoId.INSURED_VEHICLE_PLATE_CR,
		].includes(id)
	) {
		return {
			title: 'Placa Vehículo',
			imgUrl: vehiculoPlaca,
			paragraph: (
				<p>
					Tomar fotografía a la placa del vehículo{' '}
					<b>asegurándose de que sea legible</b>
				</p>
			),
		};
	} else if (
		[
			PhotoId.INJURED_VEHICLE_PLATE_CR,
			PhotoId.INJURED_VEHICLE_PLATE_CR,
		].includes(id)
	) {
		return {
			title: 'Placa Vehículo',
			imgUrl: vehiculoPlaca,
			paragraph: (
				<p>
					Tomar fotografía a la placa del vehículo{' '}
					<b>asegurándose de que sea legible</b>
				</p>
			),
		};
	} else if (
		[
			PhotoId.INSURED_VEHICLE_PLATE_PA,
			PhotoId.INSURED_VEHICLE_PLATE_PA,
		].includes(id)
	) {
		return {
			title: 'Placa Vehículo',
			imgUrl: vehiculoPlacaPA,
			paragraph: (
				<p>
					Tomar fotografía a la placa del vehículo{' '}
					<b>asegurándose de que sea legible</b>
				</p>
			),
		};
	} else if (
		[
			PhotoId.INJURED_VEHICLE_PLATE_PA,
			PhotoId.INJURED_VEHICLE_PLATE_PA,
		].includes(id)
	) {
		return {
			title: 'Placa Vehículo',
			imgUrl: vehiculoPlacaPA,
			paragraph: (
				<p>
					Tomar fotografía a la placa del vehículo{' '}
					<b>asegurándose de que sea legible</b>
				</p>
			),
		};
	} else if ([PhotoId.VEHICLE_PLATE_COL].includes(id)) {
		return {
			title: 'Placa Vehículo',
			imgUrl: vehiculoPlacaCol,
			paragraph: (
				<p>
					Tomar fotografía a la placa del vehículo{' '}
					<b>asegurándose de que sea legible</b>
				</p>
			),
		};
	} else if (
		[PhotoId.INSURED_VEHICLE_MARBETE, PhotoId.INJURED_VEHICLE_MARBETE].includes(
			id,
		)
	) {
		return {
			title: 'Marbete Vehículo',
			imgUrl: vehiculoMarbete,
			paragraph: (
				<p>
					Tomar fotografía al marbete del vehículo{' '}
					<b>asegurándose de que sea legible</b>
				</p>
			),
		};
	} else if (
		[PhotoId.INSURED_VEHICLE_VIN, PhotoId.INJURED_VEHICLE_VIN].includes(id)
	) {
		return {
			title: 'VIN Vehículo',
			imgUrl: vehiculoVin,
			paragraph: (
				<p>
					Tomar fotografía al VIN del vehículo{' '}
					<b>asegurándose de que sea legible</b>
				</p>
			),
		};
	} else if (
		[PhotoId.INSURED_VEHICLE_MOTOR, PhotoId.INJURED_VEHICLE_MOTOR].includes(id)
	) {
		return {
			title: 'Número de Motor',
			imgUrl: vehiculoVin,
			paragraph: (
				<p>
					Tomar fotografía al número de Motor del vehículo{' '}
					<b>asegurándose de que sea legible</b>
				</p>
			),
		};
	} else if (id === PhotoId.INSURED_TECHNICAL_REVIEW) {
		return {
			title: 'Riteve',
			imgUrl: vehiculoRiteve,
			paragraph: (
				<p>
					Tomar fotografía al sticker de Riteve{' '}
					<b>asegurándose de que sea legible</b>
				</p>
			),
		};
	} else if (id === PhotoId.INSURED_CIRCULATION_RIGHTS) {
		return {
			title: 'Marchamo Vehículo',
			imgUrl: vehiculoMarchamo,
			paragraph: (
				<p>
					Tomar fotografía al marchamo del vehículo{' '}
					<b>asegurándose de que sea legible</b>
				</p>
			),
		};
	} else if (id === PhotoId.INSURED_POLICIVE_PART_CR) {
		return {
			title: 'Boleta de Tránsito',
			imgUrl: accidentDeclaration,
			paragraph: (
				<p>
					Tomar fotografía de la Boleta de Tránsito{' '}
					<b>sobre una superficie plana asegurándose que sea legible</b>
				</p>
			),
		};
	} else if (id === PhotoId.COUNTERPART_POLICY) {
		return {
			title: 'Póliza',
			paragraph: (
				<p>
					Tomar fotografía al documento de la poliza{' '}
					<b>sobre una superficie plana asegurándose que sea legible</b>
				</p>
			),
		};
	} else if (id === PhotoId.COUNTERPART_FUD) {
		return {
			title: 'FUD',
			imgUrl: fud,
			paragraph: (
				<p>
					Tomar fotografía del FUD{' '}
					<b>sobre una superficie plana asegurándose que sea legible</b>
				</p>
			),
		};
	} else if (id === PhotoId.INSURED_POLICIVE_PART_PA) {
		return {
			title: 'Boleta de Tránsito',
			imgUrl: boletaPa,
			paragraph: (
				<p>
					Tomar fotografía de la Boleta de Tránsito{' '}
					<b>sobre una superficie plana asegurándose que sea legible</b>
				</p>
			),
		};
	} else if (
		[
			PhotoId.OTHERS_1,
			PhotoId.OTHERS_2,
			PhotoId.OTHERS_1_CRC,
			PhotoId.OTHERS_1_PAN,
			PhotoId.OTHERS_2_CRC,
			PhotoId.OTHERS_2_PAN,
		].includes(id)
	) {
		return {
			title: 'Otras fotos',
			imgUrl: otrosImgUrl,
			paragraph: (
				<div>
					<p>
						En esta sección puedes agregar fotos adicionales que consideres
						necesarias, entre ellas:
					</p>
					<ul>
						<li>Documentos</li>
						<li>Vehículo</li>
						<li>Lugar del incidente</li>
					</ul>
				</div>
			),
		};
	} else if ([PhotoId.FIXED_OBJECT].includes(id)) {
		return {
			title: 'Objeto Fijo',
			imgUrl: crash,
			paragraph: (
				<p>
					Tomar foto panorámica del accidente,{' '}
					<b>donde se pueda observar la colisión con el objeto fijo.</b>
				</p>
			),
		};
	} else if (
		[
			PhotoId.INSURED_VEHICLE_DOCUMENT,
			PhotoId.INSURED_VEHICLE_DOCUMENT_CRC,
			PhotoId.INSURED_VEHICLE_DOCUMENT_PAN,
		].includes(id)
	) {
		return {
			title: 'Documento',
			imgUrl:
				id === PhotoId.INSURED_VEHICLE_DOCUMENT_CRC ? circulacioncr : undefined,
			paragraph: (
				<p>
					Tomar fotografía del documento{' '}
					<b>sobre una superficie plana asegurándose que sea legible</b>
				</p>
			),
		};
	} else if ([PhotoId.INSURED_VEHICLE_RUV_PAN].includes(id)) {
		return {
			title: 'Registro Único',
			imgUrl: ruvPa,
			paragraph: (
				<p>
					Tomar fotografía del Registro Único Vehícular{' '}
					<b>sobre una superficie plana asegurándose que sea legible</b>
				</p>
			),
		};
	} else if ([PhotoId.PORTRAIT].includes(id)) {
		return {
			title: 'Retrato',
			imgUrl: portrait,
			paragraph: (
				<p>
					Tomar fotografía retrato del cliente <b>tipo selfie</b>
				</p>
			),
		};
	} else if ([PhotoId.TRUNK].includes(id)) {
		return {
			title: 'Cajuela',
			imgUrl: trunk,
			paragraph: (
				<p>
					Tomar fotografía de la <b>cajuela del vehículo</b>
				</p>
			),
		};
	} else if ([PhotoId.DEKRA].includes(id)) {
		return {
			title: 'Hoja de revisión técnica',
			imgUrl: dekra,
			paragraph: (
				<p>
					Tomar fotografía de la <b>hoja de revisión técnica de Dekra</b>
				</p>
			),
		};
	} else if ([PhotoId.INSURED_DRIVER_LICENSE_BACK].includes(id)) {
		return {
			title: 'Licencia de Conducir (Trasera)',
			imgUrl: licenseBackPa,
			paragraph: (
				<p>
					Tomar fotografía de la licencia (Trasera){' '}
					<b>sobre una superficie plana asegurándose que sea legible</b>
				</p>
			),
		};
	} else if ([PhotoId.BACK_CEDULA_CR].includes(id)) {
		return {
			title: 'Cédula de identidad trasera',
			imgUrl: backcedulacr,
			paragraph: (
				<p>
					Tomar fotografía de la cédula{' '}
					<b>sobre una superficie plana asegurándose que sea legible</b>
				</p>
			),
		};
	} else {
		return null;
	}
}

interface TooltipContent {
	title: string;
	imgUrl?: string;
	paragraph: React.ReactNode;
}

function Content({ content }: { content: TooltipContent }) {
	return (
		<div className="content">
			{content.imgUrl && (
				<div className="image-container">
					<img src={content.imgUrl!} />
				</div>
			)}
			{content.paragraph}
			<button>Entendido</button>
		</div>
	);
}

export default function CameraTooltip({ photoId }: { photoId: PhotoId }) {
	const [opened, setOpened] = useState(false);

	const toggleOpened = useCallback(() => {
		setOpened(!opened);
	}, [opened, setOpened]);

	const contents = getTooltipContents(photoId);
	if (!contents) {
		return null;
	}

	return (
		<div className={classnames('camera-tooltip-container', { opened })}>
			<div className="camera-tooltip" onClick={toggleOpened}>
				<div className="header">
					<span>{contents.title}</span>
					<InfoIcon className="info-icon" />
				</div>
				{opened && <Content content={contents} />}
			</div>
		</div>
	);
}
